<template>
  <v-app class="bg-grey-lighten-3">
    <v-app-bar :elevation="2">
      <template #title>
        <NuxtLink to="/teambuilding">
          <div class="flex flex-row align-center font-bold">
            <img :src="logo" class="object-contain h-24 w-24 p-2"></img> <span>Team Building</span>
          </div>
        </NuxtLink>
      </template>
      <template #prepend>
        <v-icon icon="mdi-menu" @click.stop="drawer = !drawer">
          mdi-menu
        </v-icon>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer">
      <v-list-item>
        <template #title>
          <span class="text-lg font-weight-bold">Activities</span>
        </template>
      </v-list-item>
      <v-divider />
      <v-list-item link nuxt title="Lunch" to="/teambuilding/lunch" />
      <v-list-item link title="Sport" />
      <v-list-item link title="Travel" to="/teambuilding/travel" />
      <v-list-item>
        <template #title>
          <span class="text-lg font-weight-bold">Gallery</span>
        </template>
      </v-list-item>
      <v-divider />
      <v-list-item link title="Lunch" />
      <v-list-item link title="Sport" />
      <v-list-item link title="Travel" />
    </v-navigation-drawer>
    <v-responsive>
      <v-main>
        <NuxtPage />
      </v-main>
    </v-responsive>
  </v-app>
</template>

<script lang="ts" setup>
import logo from '~/assets/img/logo.jpeg'

const drawer = ref(false)

</script>

<style>

</style>
